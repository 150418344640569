<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Select the proper equation for the reaction quotient for the following reaction:
      </p>

      <p class="mb-2 pl-8">
        <chemical-latex content="U(s) + 3 F2(g) <=> UF6(s)" />
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-n3">
        <div v-for="option in options" :key="option.value" class="mb-2">
          <v-radio class="d-inline-block pt-3" :value="option.value" />
          <stemble-latex :content="option.condition" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question101',
  components: {StembleLatex, ChemicalLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options: [
        {
          condition: '$\\text{Q}_\\text{c} = \\displaystyle{\\frac{\\ce{[U][F2]}^3}{\\ce{[UF6]}}}$',
          value: '1',
        },
        {
          condition: '$\\text{Q}_\\text{c} = \\ce{[F2]}^3$',
          value: '2',
        },
        {
          condition: '$\\text{Q}_\\text{c} = \\displaystyle{\\frac{\\ce{1}}{\\ce{[F2]}^3}}$',
          value: '3',
        },
        {
          condition: '$\\text{Q}_\\text{c} = \\displaystyle{\\frac{\\ce{[UF6]}}{\\ce{[U][F2]}^3}}$',
          value: '4',
        },
        {
          condition: '$\\text{Q}_\\text{c} = \\displaystyle{\\frac{\\ce{1}}{\\ce{3[F2]}}}$',
          value: '5',
        },
        {
          condition: '$\\text{Q}_\\text{c} = \\displaystyle{\\frac{\\ce{1}}{\\ce{[F2]}}}$',
          value: '6',
        },
      ],
    };
  },
};
</script>
